import { queryFn } from "~/lib/client";
import { NavigationOutletResponse } from "~/types/backend";

export function getNavOutletQuery(
  outlet: string,
  access_token: string | undefined = ""
) {
  return {
    queryKey: ["navigation", outlet],
    queryFn: queryFn<NavigationOutletResponse>(
      "GET",
      `/pagebuilder/navigation/${outlet}`,
      access_token
    ),
    enabled: access_token !== undefined,
  };
}
