import { useMutation, useQuery, useQueryClient } from "@tanstack/react-query";
import { AxiosError } from "axios";
import { useAccessToken } from "~/features/auth/api/auth-hooks";
import { apiClient, constructHeaders } from "~/lib/client";
import {
  NavigationOutletResponse,
  NavigationOutletUpdateRequest,
} from "~/types/backend";
import { getNavOutletQuery } from "./navigation-querries";

export function useGetNavOutlet(outlet: string) {
  const accessToken = useAccessToken();
  return useQuery(getNavOutletQuery(outlet, accessToken));
}

type TUpdateOutletPayload = {
  data: NavigationOutletUpdateRequest;
  outlet: string;
};

export function useUpdateNavOutlet() {
  const accessToken = useAccessToken();
  const queryClient = useQueryClient();
  const headers = constructHeaders(accessToken || "");
  return useMutation<
    NavigationOutletResponse,
    AxiosError,
    TUpdateOutletPayload
  >({
    mutationFn: async ({ data, outlet }) => {
      const response = await apiClient.put(
        `pagebuilder/navigation/${outlet}`,
        JSON.stringify(data),
        { headers }
      );
      return response.data;
    },
    onSuccess: (response, variables) => {
      queryClient.invalidateQueries({
        queryKey: ["navigation"],
      });
      queryClient.setQueryData(["navigation", variables.outlet], response);
    },
  });
}
